<template>
	<div class="Login">
		<div class="LoginMethod">
			<div
				v-if="loginMethodConfig === 'password' || loginMethodConfig === 'all'"
				@click="SwitchingMethod('password')"
				class="Method fl"
				:class="{ highlight: loginMethod }"
			>
				密码登录
				<span v-show="loginMethod"></span>
			</div>

			<div class="fl cutApart" v-if="loginMethodConfig === 'all'"></div>

			<div
				v-if="loginMethodConfig === 'code' || loginMethodConfig === 'all'"
				@click="SwitchingMethod('Verification')"
				class="Method fl"
				:class="{ highlight: !loginMethod }"
			>
				验证码登录
				<span v-show="!loginMethod"></span>
			</div>

			<div class="clear"></div>
		</div>
		<div class="EditingArea">
			<div v-show="loginMethod" class="vanField">
				<ul>
					<li>
						<van-field v-model="phone" center clearable :border="false" use-button-slot :placeholder="'请输入' + placeholderTxt" />
					</li>
					<li class="passwordInputWrapper">
						<van-field v-model="password" class="passwordStyle fl" clearable :type="isVisible" placeholder="请输入登录密码" :border="false" />

						<div class="VisibleCutApart fl"></div>

						<div class="Visible fr" @click="setVisible">
							<img class="fl" :src="isVisibleImg" alt />
						</div>

						<div class="clear"></div>
					</li>
				</ul>
			</div>
			<div v-show="!loginMethod" class="vanField">
				<ul>
					<li>
						<van-field v-model="phone" center clearable :border="false" use-button-slot :placeholder="'请输入' + placeholderTxt" />
					</li>
					<li class="authcodeInputWrapper">
						<van-field v-model="Verification" class="VerificationStyle fl" clearable placeholder="请输入验证码" :border="false" />

						<div class="getVerificationStyle fr" @click="postAccountGetAuthCode" :style="{ color: Countdown > 0 ? '#BBBBBB' : '#333333' }">
							{{ VerificationStatus }}
						</div>

						<div class="clear"></div>
					</li>
				</ul>
			</div>
		</div>
		<div class="OperatingSpace">
			<van-button round type="info" :class="phone && protocol ? 'van-button--info' : 'noneVanButton'" @click="handleLogin">登录</van-button>
			<div class="protocolStyle">
				<van-checkbox class="myCheckbox" v-model="protocol" label-disabled></van-checkbox>
				<span @click="ClearanceAgreement">我同意</span>
				<a @click="goPage('service-agreement')">《用户协议》</a>
				<span>及</span>
				<a @click="goPage('privacy')">《法律声明及隐私政策》</a>
				<div class="clear"></div>
			</div>

			<div class="botton-btn">
				<!-- <span class="item left" v-if="canRegister" @click="toRouter('/registered')">立即注册</span> -->
				<span class="item left" v-if="canRegister && !isrenshe" @click="toRouter('/registered')">立即注册</span>

				<span class="item right" @click="toRouter('/verification')">忘记密码</span>
				<div class="clear"></div>
			</div>
		</div>
	</div>
</template>
<script>
/* eslint-disable */
import md5 from 'js-md5'
import { mapState } from 'vuex'
import config from '@/config/url'
import yincang from './../asset/images/icon-yincang.png'
import xianshi from './../asset/images/icon-xianshi.png'
import { isPhone, isEmail, isIdentitycard } from '@/utils/validate'
import isrenshe from '@/utils/judgeDomain'

export default {
	name: 'login',
	data() {
		return {
			protocol: false,
			phone: null,
			password: null,
			Verification: null,
			Countdown: 0,
			countdownInterval: null,
			isVisible: 'password',
			place: 'password',
			loginMethodList: ['1', '2', '3', '4', '5'], //1,3验证码，1手机号3邮箱，           2，4，5密码 ，2手机号，4邮箱， 5身份证
			loginMethodConfig: 'all',
			logintxt: '手机号码/邮箱/身份证号码',
			canRegister: false,
            isrenshe: true, //区分当前域名是人社租户、人社子机构
            branchId:'',// 人社子机构id
            saasId:''
		}
	},
	computed: {
		loginMethod() {
			return this.place === 'password'
		},
		isVisibleImg() {
			return this.isVisible === 'password' ? yincang : xianshi
		},
		VerificationStatus() {
			if (this.Countdown) {
				return `重新获取 ${this.Countdown}s`
			} else {
				return '发送验证码'
			}
		},
		placeholderTxt() {
			if (this.loginMethodConfig === 'code' && this.logintxt.includes('身份证')) {
				let index = this.logintxt.lastIndexOf('/')
				return this.logintxt.slice(0, index)
			} else if (this.loginMethodConfig === 'all' && this.place === 'password') {
				let strToArr = this.logintxt.split('/')
				if (!this.loginMethodList.includes('2') && this.logintxt.includes('手机号码')) {
					let index = strToArr.indexOf('手机号码')
					strToArr.splice(index, 1)
				}
				if (!this.loginMethodList.includes('4') && this.logintxt.includes('邮箱')) {
					let index = strToArr.indexOf('邮箱')
					strToArr.splice(index, 1)
				}
				return strToArr.join('/')
			} else if (this.loginMethodConfig === 'all' && this.place === 'Verification') {
				let strToArr = this.logintxt.split('/')
				let ind = strToArr.indexOf('身份证号码')
				if (ind != -1) {
					strToArr.splice(ind, 1)
				}
				if (!this.loginMethodList.includes('1') && this.logintxt.includes('手机号码')) {
					let index = strToArr.indexOf('手机号码')
					strToArr.splice(index, 1)
				}
				if (!this.loginMethodList.includes('3') && this.logintxt.includes('邮箱')) {
					let index = strToArr.indexOf('邮箱')
					strToArr.splice(index, 1)
				}
				return strToArr.join('/')
			} else {
				return this.logintxt
			}
        },
		// ...mapState(['branchId']), // 人社子机构id
	},
	created() {
		this.isrenshe = isrenshe
		this.initConfig()
	},
	mounted() {
		this.phone = this.$route.query.phone || null
	},
	methods: {
		ClearanceAgreement() {
			this.protocol = !this.protocol
		},
		//   用户协议跳转页面
		goPage(url) {
			this.$router.push(url)
		},
		async getConfig() {
			return this.$api.configuration.getConfigurationNew().then((res) => {
				if (res.success) {
                    this.branchId = res.data.branchId
                    this.saasId = res.data.solutionInfo.rid
				}
			})
		},
		async initConfig() {
			/* 
                手机号+验证码 is_allow_telephone_verification_code_login 1
                手机号+密码   is_allow_telephone_password_login 2
                邮箱+验证码   is_allow_email_verification_code_login 3
                邮箱+密码     is_allow_email_password_login 4
                身份证+密码   is_allow_id_number_password_login 5
                注册方式:手机号+验证码  is_allow_telephone_register
                注册方式:邮箱+验证码    is_allow_email_register
            */
		   console.log('聊几句注册');
		   console.log(this.isrenshe);
			console.log(this.canRegister);
		   console.log(this.$store.state.dynamic.frontendConfig);
		   console.log((sessionStorage.getItem('frontendConfig')&&JSON.parse(sessionStorage.getItem('frontendConfig'))));
			let frontendConfig = this.$store.state.dynamic.frontendConfig || (sessionStorage.getItem('frontendConfig')&&JSON.parse(sessionStorage.getItem('frontendConfig'))) ||  []
			if (frontendConfig.length > 0) {
				frontendConfig.forEach((item) => {
					switch (item.key) {
						case 'is_open_register':
							this.canRegister = item.value === 'true' ? true : false
							break
						case 'is_allow_telephone_verification_code_login':
							if (item.value === 'false') {
								let index = this.loginMethodList.indexOf('1')
								this.loginMethodList.splice(index, 1)
							}
							break
						case 'is_allow_telephone_password_login':
							if (item.value === 'false') {
								let index = this.loginMethodList.indexOf('2')
								this.loginMethodList.splice(index, 1)
							}
							break
						case 'is_allow_email_verification_code_login':
							if (item.value === 'false') {
								let index = this.loginMethodList.indexOf('3')
								this.loginMethodList.splice(index, 1)
							}
							break
						case 'is_allow_email_password_login':
							if (item.value === 'false') {
								let index = this.loginMethodList.indexOf('4')
								this.loginMethodList.splice(index, 1)
							}
							break
						case 'is_allow_id_number_password_login':
							if (item.value === 'false') {
								let index = this.loginMethodList.indexOf('5')
								this.loginMethodList.splice(index, 1)
							}
							break
						default:
							break
					}
				})
				this.handleLoginTxt()
			}
			console.log('聊几句注册3343434');
		   console.log(this.isrenshe);
			console.log(this.canRegister);
		   console.log(this.$store.state.dynamic.frontendConfig);
		   console.log((sessionStorage.getItem('frontendConfig')&&JSON.parse(sessionStorage.getItem('frontendConfig'))));
		},
		handleLoginTxt() {
			let result = '手机号码/邮箱/身份证号码'
			if (!this.loginMethodList.includes('1') && !this.loginMethodList.includes('3')) {
				//密码
				result = this.getMethodTxt()
				this.loginMethodConfig = 'password'
			} else if (!this.loginMethodList.includes('2') && !this.loginMethodList.includes('4') && !this.loginMethodList.includes('5')) {
				//验证码
				result = this.getMethodTxt()
				this.loginMethodConfig = 'code'
				this.place = 'Verification'
			} else {
				//验证码+密码
				result = this.getMethodTxt()
				this.loginMethodConfig = 'all'
			}
			this.logintxt = result
		},
		getMethodTxt() {
			let hasphone,
				hasemail,
				hasidentifycard = ''
			if (this.loginMethodList.includes('1') || this.loginMethodList.includes('2')) hasphone = '手机号码'
			if (this.loginMethodList.includes('3') || this.loginMethodList.includes('4')) hasemail = '邮箱'
			if (this.loginMethodList.includes('5')) hasidentifycard = '身份证号码'
			let arr = [hasphone, hasemail, hasidentifycard].filter((item) => {
				return !!item
			})
			return arr.join('/')
		},
		toRouter(path) {
			const query = { ...this.$route.query }
			this.$router.push({ path, query })
		},
		SwitchingMethod(val) {
			this.place = val
		},
		setVisible() {
			if (this.isVisible === 'password') {
				this.isVisible = 'text'
				return 0
			}
			this.isVisible = 'password'
		},
		setCountdown() {
			this.countdownInterval = setInterval(() => {
				this.Countdown--
				if (this.Countdown <= 0) {
					this.Countdown = 0
					clearInterval(this.countdownInterval)
				}
			}, 1000)
		},
		postAccountGetAuthCode() {
			if (this.Countdown > 0) return
			const data = { account: this.phone, type: 1 }
			if (isPhone.test(this.phone) || isEmail.test(this.phone)) {
				this.Countdown = 60
				this.setCountdown()
				this.$sensors.track('UM_Click_Verification _Code_Button')
				this.$api.usercenter.getAuthCodeCommon({ data }).then((res) => {
					if (res.data) {
						this.$store.state.userInfo.phone = this.phone
						sessionStorage.setItem('WdPhone', this.phone)
					}
				})
			} else {
				this.$notify({
					type: 'warning',
					message: this.placeholderTxt + '格式有误,请检查一下吧',
				})
			}
		},
		async handleLogin() {
			// 保证本地存储最新的
			localStorage.removeItem('organ')
			localStorage.removeItem('actor')
			localStorage.removeItem('branch')

			if (!this.protocol) return

			if (!this.isrenshe && !this.branchId) {
				//机构登录，需要有branchId
                await this.getConfig()
			}

			let method = this.place === 'password' ? 'postAccountLogin' : 'postAccountLoginByAuthCode'
			const data = {
				account: this.phone,
				password: md5(this.password),
				authCode: this.Verification,
			}
			this.$store.state.distributionId && (data.distributionId = this.$store.state.distributionId)

			if (this.isrenshe) {
				//参培单位登录
				data.adminFlag = 1
            }
            
            let loginResult = await this.$api.usercenter[method]({ data })
            if(loginResult.data){
                localStorage.setItem('token', loginResult.data)
            }else{
                return
            }

			try {
                if(this.isrenshe){
                    await this.getFindOrganList()
                }else{
                    await this.getFindUserBranchList()
                }
				// await this.getFindUserBranchList()
				// if (this.isrenshe) {
				// 	//参培单位管理员登录
				// 	await this.findTrainBranchType()
				// }
			} catch (err) {
                console.log('err-------',err)
				localStorage.removeItem('token')
				if (err) return
			}

			// // 获取用户信息
			// this.getUserInfo()
			// // 获取用户id
			// this.findUserId()

			if (sessionStorage.getItem('realName')) {
				location.replace('/idcard')
			} else {
				if (this.$route.query.name) {
					this.$router.push({
						path: this.$route.query.name + window.location.search,
					})
				} else {
					//参培单位登录跳转 /home   机构学员登录跳转 /distributionHomePage
					let path = this.isrenshe ? '/home' : '/distributionHomePage'
					this.$router.push(path)
				}
			}
        },
        getFindOrganList() {
			return this.$api.rensheUsercenter.getFindOrganList().then(async (res) => {
				if (res.data && res.data.length) {
					await this.setUserOrgan(res.data)
				}else{
                    return Promise.reject(res.message)
                }
			})
		},
		async setUserOrgan(organList) {
            await this.getConfig()
            let organItem = organList.find((item) => {
                let organ = md5(this.saasId + '').toUpperCase()
                // console.log('======================',organ,item.organ)
                return item.organ === organ
            })
			if(organItem){
				localStorage.setItem('name', organItem?.name)
				localStorage.setItem('organ', organItem?.organ)
				localStorage.setItem('actor', organItem?.actor)
			}else{
				this.$notify({
                    type: 'danger',
                    message: '当前用户尚未注册，没有登录权限！',
                })
                return Promise.reject('当前用户尚未注册，没有登录权限！')
			}
			if (!organItem.status) {
                this.$notify({
                    type: 'danger',
                    message: '当前租户已被禁用',
                })
                return Promise.reject('当前租户已被禁用')
			}
			await this.getFindAdminBranchList()
		},
		getFindUserBranchList() {
			return this.$api.account.getFindUserBranchList({}).then((res) => {
				if (res.data && res.data.length) {
					const branchList = res.data

					if (branchList.length) {
						localStorage.setItem('branchList', JSON.stringify(branchList))

						let currentBranch = null
						currentBranch = branchList[0]
						if (!this.isrenshe) {
							// 登录域名指定的子机构
							currentBranch = branchList.find((item) => {
								let branch = md5(this.branchId + item.token).toUpperCase()
								// console.log(this.branchId,item.token,branch,'---',branch)
								return item.branch === branch
							})
						}

						if (currentBranch) {
							localStorage.setItem('code', currentBranch.code)
							localStorage.setItem('organ', currentBranch.organ)
							localStorage.setItem('actor', currentBranch.actor)
							localStorage.setItem('branch', currentBranch.branch)
                            currentBranch.channel && localStorage.setItem('channel', currentBranch.channel)
                            
                            // 获取用户信息
                            this.getUserInfo()
                            // 获取用户id
                            this.findUserId()
							this.$sensors.login(currentBranch.code)
						} else {
							this.$notify({
								type: 'danger',
								message: '暂未加入当前机构',
							})
							return Promise.reject('暂未加入当前机构')
						}
					}
				} else {
					return Promise.reject(res.data.message)
				}
			})
        },
        getFindAdminBranchList(){
            return this.$api.rensheUsercenter.getFindAdminBranchList().then(async (res) => {
				if (res.data && res.data.length) {
					const branchList = res.data

					if (branchList.length) {
						localStorage.setItem('branchList', JSON.stringify(branchList))
						
						let currentBranch = branchList[0]
                        if(currentBranch){
                            localStorage.setItem('code', currentBranch.code)
                            localStorage.setItem('organ', currentBranch.organ)
                            localStorage.setItem('actor', currentBranch.actor)
                            localStorage.setItem('branch', currentBranch.branch)
                            localStorage.setItem('branchName', currentBranch.name)
                            localStorage.setItem('userId', currentBranch.userId)
                            localStorage.setItem('channel',1)//管理员

                            // this.$store.commit('setBranchName',currentBranch.name)

                            await this.findTrainBranchType()
                            this.$sensors.login(currentBranch.code)
                        }
					}
				} else {
					return Promise.reject(res.message)
				}
			})
        },
		// 判断当前培训机构类型 0 教学资源合作机构 1 参培单位
		findTrainBranchType() {
			return this.$api.rensheUsercenter.findTrainBranchType({phone:this.phone}).then((res) => {
				if (res.success && res.data === 0) {
					this.$notify({
						type: 'warning',
						message: '暂无权限登录',
					})
					return Promise.reject('暂无权限登录')
				}
				if (!res.success) {
					return Promise.reject(res.message)
                }
                
                // 获取用户信息
                this.getUserInfo()
			})
		},
		getUserInfo() {
			this.$api.usercenter.getAccountUserInfo({}).then((res) => {
				localStorage.setItem('WDuserInfo', JSON.stringify(res.data))
				this.$store.commit('setUserInfo', res.data)
			})
		},
		// 查询用户id
		findUserId() {
			this.$api.usercenter.findUser().then((res) => {
				if (res.code == 0) {
					let subUser = { id: res.data.id }
					localStorage.setItem('subUserInfo', JSON.stringify(subUser))
				}
			})
		},
	},
}
</script>
<style lang="stylus" scoped>
.protocolStyle {
  // margin-top 48px
  margin-bottom: 24px;
  white-space: nowrap;

  /deep/.van-checkbox {
    margin-right: 8px;
    display: inline-block;
    vertical-align: middle;
  }

  span, a {
    display: inline-block;
    font-size: 26px !important;
    line-height: 30px;
    margin-top: 6px;
  }

  span {
    color: #666666;
  }

  a {
    color: #3760FF;
  }
}

.Login {
  background-color: #FFFFFF;
  min-height: 100vh;
}

.LoginMethod {
  padding-left: 32px;
  padding-top: 122px;

  .Method {
    padding: 0 32px;
    font-size: 44px;
    font-weight: 900;
    color: #BBBBBB;
    position: relative;

    span {
      width: 48px;
      height: 8px;
      display: inline-block;
      background-color: #3660FF;
      border-radius: 4px;
      position: absolute;
      margin-left: -24px;
      bottom: -24px;
      left: 50%;
    }

    &.highlight {
      color: #333333;
    }
  }

  .cutApart {
    width: 2px;
    height: 44px;
    margin-top: 10px;
    border-radius: 1px;
    background-color: #D7D7D7;
  }
}

.EditingArea {
  width: 622px;
  padding-top: 55px;
  position: relative;
  margin-left: -311px;
  left: 50%;

  li {
    padding: 1px 0;
    height: 122px;
    overflow: hidden;
    border-bottom: 1px solid #D7D7D7;

    .passwordStyle {
      width: 548px;
      display: inline-block;
    }

    .VisibleCutApart {
      margin: 0 24px;
      width: 2px;
      height: 32px;
      border-radius: 1px;
      background-color: #D7D7D7;
    }

    .Visible {
      display: inline-block;

      img {
        width: 48px;
      }
    }
  }

  li.passwordInputWrapper {
    display: flex;
    align-items: center;
  }

  li.authcodeInputWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .van-cell {
    padding: 0;
    height: 58px;
    line-height: 58px;
    font-size: 18px;
    background: transparent;
  }
}

.OperatingSpace {
  width: 622px;
  position: relative;
  margin-left: -311px;
  left: 50%;

  span {
    color: #333333;
    font-size: 30px;
  }

  .van-button {
    width: 100%;
    height: 44px;
    line-height: 44px;
    margin-top: 32px;
    margin-bottom: 24px;

    span {
      color: #FFFFFF;
      font-size: 16px;
    }
  }

  .noneVanButton {
    background-color: #C0D5FF;
    border: 1px solid #C0D5FF;
  }

  .van-button--info {
    background-color: linear-gradient(136deg, #227FFF 0%, #316FFF 100%);
    border: 1px solid linear-gradient(136deg, #227FFF 0%, #316FFF 100%);
  }
}

.VerificationStyle {
  width: 420px;
}

.getVerificationStyle {
  color: #333333;
  font-size: 30px;
  // padding 40px 0
}

.botton-btn {
  display: flex;

  .item {
    flex: 1;
  }

  .left {
    text-align: left;
  }

  .right {
    text-align: right;
  }
}
</style>